import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as Sentry from '@sentry/angular-ivy';

@Component({
    selector: 'app-image-detail-dummy-page',
    templateUrl: './image-detail-dummy-page.component.html',
    styleUrls: ['./image-detail-dummy-page.component.scss']
})
@Sentry.TraceClassDecorator()
export class ImageDetailDummyPageComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (route.snapshot && route.snapshot.queryParams && route.snapshot.queryParams['id']) {
            this.router.navigate(['/image/' + this.route.snapshot.queryParams['id']]);
        } else {
            this.router.navigate(['/']);
        }
    }
}
