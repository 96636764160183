import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Event, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { TagAddComponent } from '@components/tag-add/tag-add.component';
import { AddCropDialogComponent } from '@components/add-crop-dialog/add-crop-dialog.component';
import { AuthService } from '@auth0/auth0-angular';
import { CropResolutionService } from '@services/cropresolution.service';
import { AppState } from '@services/appstate.service';
import { AblyService } from '@services/ably.service';
import { CropResolutionActions } from '@actions/cropresolution.actions';
import { getImageSearchTotalRows, getCropResolutionEntities } from './reducers';

import * as Sentry from '@sentry/angular-ivy';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
@Sentry.TraceClassDecorator()
export class AppComponent implements OnInit {
    dialogRef: MatDialogRef<TagAddComponent>;
    addCropDialogRef: MatDialogRef<AddCropDialogComponent>;
    cropResolutions$: Observable<any>;
    cropResolutions: any;
    showMobileMenu = false;
    searchTotalRows$: Observable<number>;
    loading = true;

    cropResTool: boolean = false;
    allowImageUpload = false;
    allowTagManager = false;
    allowNewTags = false;

    cropUrl: string;

    constructor(
        private store: Store<AppState>,
        public dialog: MatDialog,
        public auth: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private ablyService: AblyService,
        private http: HttpClient,
        private cropResolutionActions: CropResolutionActions,
        private cropResService: CropResolutionService,
        private jwtHelper: JwtHelperService
    ) {
        // Handle authenticated state
        combineLatest([this.auth.isAuthenticated$, this.auth.idTokenClaims$])
            .pipe(
                filter(([isAuthenticated, claims]) => {
                    if (isAuthenticated && claims != null) {
                        if (this.jwtHelper.isTokenExpired(claims.__raw)) {
                            this.authenticate();
                            return false;
                        }
                        return true;
                    }
                    this.authenticate();
                    return false;
                }),
                first(),
                tap(([_, claims]) => {
                    localStorage.setItem('id_token', claims.__raw);
                    const profile = { ...claims };
                    delete profile.__raw;
                    localStorage.setItem('profile', JSON.stringify(profile));
                })
            ).subscribe();
        this.ablyService.start();
    }

    ngOnInit() {
        this.auth.isAuthenticated$
            .pipe(filter((isAuthenticated) => isAuthenticated === true)) // Only proceed if authenticated
            .subscribe(() => {
                const profile = JSON.parse(localStorage.getItem('profile'));

                if (profile != null) {
                    this.setProfileRights(profile);
                } else {
                    this.auth.idTokenClaims$.subscribe((claims) => {
                        localStorage.setItem('id_token', claims.__raw);
                        const profile = JSON.parse(JSON.stringify(claims));
                        delete profile.__raw;
                        localStorage.setItem('profile', JSON.stringify(profile));
                        this.setProfileRights(profile);
                    });
                }

                this.router.events
                    .pipe(filter(event => event instanceof NavigationEnd)
                        , map(() => this.activatedRoute)
                        , map(route => {
                            while (route.firstChild) {
                                route = route.firstChild;
                            }
                            return route;
                        })
                        , filter(route => route.outlet === 'primary')
                        , mergeMap(route => route.data))
                    .subscribe((event) => {
                        if (event['title'] != null) {
                            this.titleService.setTitle(event['title']);
                        }
                    });

                this.router.events.subscribe((routerEvent: Event) => {
                    this.checkRouterEvent(routerEvent);
                });
                this.activatedRoute.queryParams.subscribe(queryParams => {
                    if (queryParams['crop-res-tool'] != null && queryParams['crop-res-tool'] === 'true') {
                        this.getIPAddress().subscribe((res: any) => {
                            if (res.ip === '62.251.83.180') {
                                if (profile != null && profile['userData'] != null && profile['https://mediaspirit.io/app_metadata'] != null) {
                                    if (profile['https://mediaspirit.io/app_metadata'].rights &&
                                        profile['https://mediaspirit.io/app_metadata'].rights.indexOf('cropResAdd') > -1) {
                                        this.cropResTool = true;
                                    }
                                }
                            }
                        });
                    }
                });
            });
    }

    setProfileRights(profile: any) {
        if (profile['https://mediaspirit.io/app_metadata'] != null) {
            if (profile['https://mediaspirit.io/app_metadata'].rights) {
                if (profile['https://mediaspirit.io/app_metadata'].rights.indexOf('tagAdd') > -1) {
                    this.allowNewTags = true;
                }
                if (profile['https://mediaspirit.io/app_metadata'].rights.indexOf('tagAdd') > -1 ||
                    profile['https://mediaspirit.io/app_metadata'].rights.indexOf('tagDelete') > -1) {
                    this.allowTagManager = true;
                }
                if (profile['https://mediaspirit.io/app_metadata'].rights.indexOf('imageUpload') > -1) {
                    this.allowImageUpload = true;
                }
            }
        }
        this.store.dispatch(this.cropResolutionActions.fetchCropResolutions());
        this.cropResolutions$ = this.store.pipe(getCropResolutionEntities());
        this.cropResolutions$.subscribe(x => {
            this.cropResolutions = x;
            const cropResKeys = Object.keys(this.cropResolutions);
            if (cropResKeys.length > 0) {
                this.cropResolutions = this.formatCropResolutions(cropResKeys);
            }
        });
        this.searchTotalRows$ = this.store.pipe(getImageSearchTotalRows());
    }

    openAddTag() {
        this.dialogRef = this.dialog.open(TagAddComponent, {
            disableClose: false
        });

        this.dialogRef.afterClosed().subscribe(result => {
            this.dialogRef = null;
        });
    }

    authenticate() {
        this.auth.loginWithRedirect({ appState: { target: '/' } });
    }

    logout() {
        localStorage.removeItem('profile');
        localStorage.removeItem('id_token');
        this.auth.logout(
            {
                logoutParams: {
                    returnTo: window.location.origin // Dynamically resolve origin
                }
            }
        );
    }

    checkRouterEvent(routerEvent: Event): void {
        if (routerEvent instanceof NavigationStart) {
            this.loading = true;
        }

        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError) {
            this.loading = false;
        }
    }

    AddCropRes() {
        this.addCropDialogRef = this.dialog.open(AddCropDialogComponent, {
            disableClose: false
        });
        this.addCropDialogRef.componentInstance = this.cropResolutions;

        this.addCropDialogRef.afterClosed().subscribe(res => {
            if (res.extention === 'keep') {
                delete res.extention;
            }
            if (res && res.result === true) {
                delete res.result;
                delete res.height;
                res.url = this.cropUrl;
                if (res.ratio === 0) {
                    res.ratio = 1;
                }
                this.cropResService.upload(res).subscribe((x) => {
                    console.log('Works :D')
                }, (err) => {
                    console.log(err)
                });
            }
            this.addCropDialogRef = null;
        });
    }

    getIPAddress() {
        return this.http.get('https://ipapi.co/json/');
    }

    formatCropResolutions(keys: any) {
        const res = [];
        for (const key of keys) {
            if (this.cropResolutions[key] && this.cropResolutions[key].url) {
                this.cropUrl = this.cropResolutions[key].url;
            }
            if (this.cropResolutions[key].ratio > 0 || this.cropResolutions[key].height != null) {
                res.push({
                    name: this.cropResolutions[key].description,
                    path: this.cropResolutions[key].path,
                    width: this.cropResolutions[key].width,
                    height: this.cropResolutions[key].height || Number(this.cropResolutions[key].width / this.cropResolutions[key].ratio).toFixed(0),
                    ratio: Number(this.cropResolutions[key].ratio).toFixed(2),
                });
            }
        }
        return res;
    }

    showMobileMenuItems() {
        this.showMobileMenu = !this.showMobileMenu;
    }

    toHome() {
        if (this.router.url === '/image-search') {
            location.reload();
        } else {
            this.router.navigate(['/']);
        }
    }
}
