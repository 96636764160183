import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, ApolloModule, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const MAX_RETRIES = 1;

const auth = setContext(() => {
    const token = localStorage.getItem('id_token');
    if (token === null) {
        return {};
    } else {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    }
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    let retryCount = 0;

    if (graphQLErrors) {
        if (retryCount < MAX_RETRIES) {
            retryCount++;
            return forward(operation); // Retry the request
        }
    }

    if (networkError) {
        if (retryCount < MAX_RETRIES) {
            retryCount++;
            return forward(operation); // Retry the request
        }
    }

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
    }
    console.error(`[Network error]: ${networkError}`);
    console.error(`Retries exhausted for operation ${operation.operationName}`);
    return null; // Return null or handle gracefully
});

export function createApollo(httpLink: HttpLink): NamedOptions {
    const defaultLink = ApolloLink.from([
        errorLink,
        auth,
        httpLink.create({
            uri: 'https://api.travelspirit.cloud/graphql'
        })
    ]);

    return {
        default: {
            link: defaultLink,
            cache: new InMemoryCache({
                addTypename: false
            })
        }
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule { }
